import React from 'react'
import PlaylistItem from './PlaylistItem'
import BigButton from '../Ui/BigButton'

const Playlist = ({ videos, title, button }) => {
  return (
        <div className={'pt-24'}>
            <h3 className={'text-2xl text-center font-bold text-white mb-5'}>{title}</h3>
            <div className={'flex flex-wrap'}>
                {videos && videos.map(({ youtube_video: youtubeVideo, video_title: videoTitle }) => {
                  return (
                        // eslint-disable-next-line react/jsx-key
                        <PlaylistItem video={youtubeVideo} title={videoTitle.text}/>
                  )
                })}
            </div>
            <div className="mt-8 text-center">
                <p className="mx-auto max-w-fit">
                <BigButton text={button.text ? button.text : 'Voir les autres vidéos'} href={button.url} largeText={true} />
                </p>
            </div>

        </div>
  )
}

export default Playlist
