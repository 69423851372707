import React, {useLayoutEffect, useRef} from "react";
import {getYoutubeId} from "../../utils/youtubeEmbedUrl";

const PlaylistItem = ({ video, title }) => {
    const containerRef = useRef();

    const videoEmbedUrlId = getYoutubeId(video.embed_url)

    useLayoutEffect(()=>{
        if (containerRef.current){
            containerRef.current.firstElementChild.classList.add('w-full', 'h-full','absolute','top-0','left-0','mb-0');
        }
    });
    return (
        <div className={"w-full sm:w-1/2 md:w-1/3 sm:px-5"}>
            <div ref={containerRef} className={"relative mb-5 overflow-hidden h-0 w-full pb-56/25"}>
                <iframe title={title} src={`//www.youtube.com/embed/${videoEmbedUrlId}?feature=oembed`} frameborder="0" referrerPolicy="origin-when-cross-origin" allowFullScreen={true} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"></iframe>
            </div>
            <h4 className="text-lg font-bold text-white mb-5 uppercase">
                {title}
            </h4>
        </div>

    );
};

export default PlaylistItem;
