import React from "react";
import { Link } from "gatsby";

const Button = ({ text, target, href, minimal, className, textColor }) => {
  return target ? (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className={`${className} ${
        minimal
          ? "font-gaksans"
          : "hover:bg-red uppercase font-tradeGothic font-bold px-4 bg-navy rounded-full tracking-widest text-base pt-2.5 pb-1.5"
      } ${textColor ? textColor : "text-white"}`}
      href={href}
    >
      {text}
    </a>
  ) : (
    <Link
      to={href}
      className={`${className} ${
        minimal
          ? "font-gaksans"
          : "hover:bg-red uppercase font-tradeGothic font-bold px-4 bg-navy rounded-full tracking-widest text-base pt-2.5 pb-1.5"
      } ${textColor ? textColor : "text-white"}`}
    >
      {text}
    </Link>
  );
};

export default Button;
