import React, {useEffect, useRef, useState} from "react";
import GalleryItem from "./GalleryItem";
import {Swiper, SwiperSlide} from "swiper/react";
import ReactPortal from "../Utils/ReactPortal";
import { Thumbs,Navigation } from 'swiper';
import {GatsbyImage} from "gatsby-plugin-image";
import {Button} from "../Ui";

const Gallery = ({ images, children, title, button }) => {
    const [isOpenGallery, setIsOpenGallery] = useState(false)
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const popup = useRef()

    useEffect(() => {

        if (isOpenGallery){
            document.body.style.overflow = "hidden"
            popup.current.focus()
        }
        else {
            document.body.style.overflow = null
        }

    }, [isOpenGallery])

    return (
        <div className={"sm:px-4 w-auto sm:w-1/2 md:w-1/3"}>
            <button className="group cursor-pointer" onClick={() => setIsOpenGallery(!isOpenGallery)}>
                <div className={"mb-5"}>
                    {images &&
                        <article className={"overflow-hidden"}>
                            <GalleryItem className="h-0 pb-56/25" alt={images[0].gallery_image.alt ? images[0].gallery_image.alt : ""} image={images[0].gallery_image.gatsbyImageData}/>
                        </article>
                    }
                </div>
                <h3 className={"text-left group-hover:text-red-hover text-lg font-bold text-white mb-5 uppercase"}>{title}</h3>
            </button>
            <ReactPortal id="gallery-popup">
                <div ref={popup} tabIndex='-1' role="dialog" aria-modal={true} className={`${!isOpenGallery && "hidden"} fixed w-full z-50 top-0 min-h-screen flex justify-center items-center bg-black-opacity95`}>
                    <button title="close button" onClick={() => setIsOpenGallery(!isOpenGallery)} id="burger" className="w-8 h-5 cursor-pointer absolute top-0 right-0 m-4">
                        <span className={`absolute w-full h-0.75 bg-white rounded-lg top-2 left-0 rotate-45`} />
                        <span className={`absolute w-full h-0.75 bg-white rounded-lg top-2 left-0 -rotate-45`} />
                    </button>
                    <div className={"w-full h-screen overflow-auto flex cannothover:landscape:block justify-center items-center"}>
                        <div className={"w-4/5 md:w-3/5 lg:w-2/4 cannothover:landscape:mx-auto"}>
                            <Swiper
                                loop={true}
                                navigation={true}
                                modules={[Thumbs,Navigation]}
                                className={`cannothover:landscape:min-h-screen cannothover:landscape:flex cannothover:landscape:items-center`}
                                thumbs={{ swiper: thumbsSwiper }}
                            >
                                {images && images.map(({gallery_image}) => {
                                        return (
                                            <SwiperSlide className={"!h-auto"}>
                                                <GalleryItem alt={gallery_image.alt ? gallery_image.alt : ""} image={gallery_image.gatsbyImageData}/>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                                <SwiperSlide className={"!h-auto"}>
                                    <div className={"h-full w-full flex items-center justify-center bg-white"}>
                                        <Button target={"_blank"} text={button.text ? button.text : "Consulter la galerie complète"} href={button.url}/>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            <Swiper
                                loop={true}
                                modules={[Thumbs]}
                                className="cannothover:landscape:hidden"
                                slidesPerView={3}
                                onSwiper={setThumbsSwiper}
                                watchSlidesProgress
                            >
                                {images && images.map(({gallery_image}) => {
                                    return (
                                        <SwiperSlide className={"!h-auto p-2 md:p-8"}>
                                            <GatsbyImage alt={gallery_image.alt ? gallery_image.alt : ""} image={gallery_image.gatsbyImageData}/>
                                        </SwiperSlide>
                                    )
                                })
                                }
                                <SwiperSlide className={"!h-auto p-2 md:p-8"}>
                                    <div className={"h-full w-full flex items-center justify-center bg-white"}>
                                        <Button target={"_blank"} minimal={true} text={button.text ? button.text : "Consulter la galerie complète"} href={button.url}/>
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                        </div>
                    </div>

                </div>
            </ReactPortal>

        </div>
    );
};

export default Gallery;
