import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

const GalleryItem = ({className,alt, image}) => {
    return (
        <GatsbyImage className={`${className} transition-transform group-hover:scale-105 w-full`} image={image} alt={alt} />
    );
};

export default GalleryItem;
